import React, { useState, useEffect, Key } from "react";
import Slider from "react-slick";
import i18next from "i18next";
import { Container, Row } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from "react-router-dom";
import api from "../../../services/api";

function BannerHomeIntegrated() {

    const [banners, setBanners] = useState<any>(null);
    const [url, setUrl] = useState<any>(null);

    const settings = {
        /* dots: true, */
        draggable: false,
        useTransform: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5500,
        cssEase: "ease-out",
    };

    //***Caso de promoção com tempo determinado */
    // let date = new Date().toLocaleString();

    // let dateNow = date.split(" ")[0].split("/").reverse().join("");
    // let timeNow = date.split(" ")[1].split(":").splice(0, 2).join("");



    // if (`${dateNow}${timeNow}` <= "202208010000") {

    // } else {

    // }

    //***Caso de promoção com tempo determinado */

    const typeDevice = window.innerWidth < 480 ? "mobile" : "desktop";


    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function getBanners() {
            try {
                const { data } = await api.get(`/Banner/GetBannersAsync`, config);
                if (data.status !== 400) {
                    setBanners(data.data.data.bannerList);
                    setUrl(data.data.data.url);  
                }
            } catch (error: any) {
            }
        }
        getBanners();
    }, []);
    

    return (
        <div className="bg-main-banner">
            {
                banners !== null &&
                <Slider {...settings}>                    
                    {banners.filter((e: any) => {
                        console.log(banners)
                        //***Caso de promoção com tempo determinado */
                        let date = new Date().toLocaleString();
                        let start = new Date(e.bannerDateStart).toLocaleString();
                        let end = new Date(e.bannerDateEnd).toLocaleString();

                        let dateNow = date.split(" ")[0].split("/").reverse().join("");
                        let timeNow = date.split(" ")[1].split(":").splice(0, 2).join("");

                        let dateStart = start.split(" ")[0].split("/").reverse().join("");
                        let timeStart = start.split(" ")[1].split(":").splice(0, 2).join("");

                        let dateEnd = end.split(" ")[0].split("/").reverse().join("");
                        let timeEnd = end.split(" ")[1].split(":").splice(0, 2).join("");

                        return ((`${dateNow}${timeNow}` >= `${dateStart}${timeStart}` && `${dateNow}${timeNow}` <= `${dateEnd}${timeEnd}`) && e.bannerActivate === 1)
                    }
                    ).map((elem: any, index: Key) => {
                        console.log(elem)
                        return (
                            
                            <a
                                key={index}
                                href={
                                    i18next.language === "pt"
                                        ? elem.bannerUrlPortugues
                                        : i18next.language === "en"
                                            ? elem.bannerUrlEnglish
                                            : i18next.language === "es"
                                                ? elem.bannerUrlSpanish
                                                : elem.bannerUrlPortugues
                                }
                            >
                                <Container className="bg-secondary banner-home p-0 m-0" fluid>
                                    <Container className="p-0 m-0" fluid>
                                        <Row className="p-0 m-0">
                                            {typeDevice === "desktop" ? (
                                                <img
                                                    className="p-0 m-0"
                                                    src={
                                                        i18next.language === "pt"
                                                            ? `${url}${elem.bannerImgPortuguesDesktop}`
                                                            : i18next.language === "en"
                                                                ? `${url}${elem.bannerImgEnglishDesktop}`
                                                                : i18next.language === "es"
                                                                    ? `${url}${elem.bannerImgSpanishDesktop}`
                                                                    : `${url}${elem.bannerImgPortuguesDesktop}`
                                                    }
                                                    alt={`${elem.bannerTitle} Desktop`}
                                                    loading="lazy"
                                                />
                                            ) : (
                                                <img
                                                    className="p-0 m-0"
                                                    src={
                                                        i18next.language === "pt"
                                                            ? `${url}${elem.bannerImgPortuguesMobile}`
                                                            : i18next.language === "en"
                                                                ? `${url}${elem.bannerImgEnglishMobile}`
                                                                : i18next.language === "es"
                                                                    ? `${url}${elem.bannerImgSpanishMobile}`
                                                                    : `${url}${elem.bannerImgPortuguesMobile}`
                                                    }
                                                    alt={`${elem.bannerTitle} Mobile`}
                                                    loading="lazy"
                                                />
                                            )}
                                        </Row>
                                    </Container>
                                </Container>
                            </a>

                        )
                    })}

                </Slider>
            }
        </div>
    );

}

export default BannerHomeIntegrated;
