import React, { Component } from "react";
import Slider from "react-slick";
import i18next from "i18next";
import { Container, Row } from "react-bootstrap";

import Banner03C2DesktopPt from "../../../assets/img/banners/transfer-pt.jpg";
import Banner03C2DesktopEn from "../../../assets/img/banners/transfer-en.jpg";
import Banner03C2DesktopEs from "../../../assets/img/banners/transfer-es.jpg";

import Banner03C2MobilePt from "../../../assets/img/banners/transfer-mobile-pt.jpg";
import Banner03C2MobileEn from "../../../assets/img/banners/transfer-mobile-en.jpg";
import Banner03C2MobileEs from "../../../assets/img/banners/transfer-mobile-es.jpg";

import rioBoatTourDeskPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho.jpg";
import rioBoatTourDeskEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en.jpg";
import rioBoatTourDeskEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es.jpg";

import rioBoatTourMobPt from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-mobile.jpg";
import rioBoatTourMobEn from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-en-mobile.jpg";
import rioBoatTourMobEs from "../../../assets/img/banners-rio-boat-julho/rio-boat-tour-julho-es-mobile.jpg";

// import petropolisDesktopPt from "../../../assets/img/banners-petropolis/petropolis-01-pt.jpg";
// import petropolisDesktopEs from "../../../assets/img/banners-petropolis/petropolis-01-es.jpg";
// import petropolisDesktopEn from "../../../assets/img/banners-petropolis/petropolis-01-en.jpg";

// import petropolisMobilePt from "../../../assets/img/banners-petropolis/petropolis-01-pt-mobile.jpg";
// import petropolisMobileEs from "../../../assets/img/banners-petropolis/petropolis-01-es-mobile.jpg";
// import petropolisMobileEn from "../../../assets/img/banners-petropolis/petropolis-01-en-mobile.jpg";

// import tourCervejeiroDesktopPt from "../../../assets/img/banners-petropolis/petropolis-02-pt.jpg";
// import tourCervejeiroDesktopEs from "../../../assets/img/banners-petropolis/petropolis-02-es.jpg";
// import tourCervejeiroDesktopEn from "../../../assets/img/banners-petropolis/petropolis-02-en.jpg";

// import tourCervejeiroMobilePt from "../../../assets/img/banners-petropolis/petropolis-02-pt-mobile.jpg";
// import tourCervejeiroMobileEs from "../../../assets/img/banners-petropolis/petropolis-02-es-mobile.jpg";
// import tourCervejeiroMobileEn from "../../../assets/img/banners-petropolis/petropolis-02-en-mobile.jpg";

// import rioBoatNoturnoDesktopPt from "../../../assets/img/banners-rio-boat-tour-noturno/banner-desktop_PT.jpg";
// import rioBoatNoturnoDesktopEs from "../../../assets/img/banners-rio-boat-tour-noturno/banner-desktop_ESP.png";
// import rioBoatNoturnoDesktopEn from "../../../assets/img/banners-rio-boat-tour-noturno/banner-desktop_ING.png";

// import rioBoatNoturnoMobilePt from "../../../assets/img/banners-rio-boat-tour-noturno/banner-mobile-pt.jpg"
// import rioBoatNoturnoMobileEs from "../../../assets/img/banners-rio-boat-tour-noturno/banner-mobile_ESP.png"
// import rioBoatNoturnoMobileEn from "../../../assets/img/banners-rio-boat-tour-noturno/banner-mobile_ING.png"

import BoatPrivativoDesktopPt from "../../../assets/img/banners/banners-boat-privativo/desktop_ 1920x530px.jpg"
import BoatPrivativoDesktopEs from "../../../assets/img/banners/banners-boat-privativo/desktop_ 1920x530px_es.jpg"
import BoatPrivativoDesktopEn from "../../../assets/img/banners/banners-boat-privativo/desktop_ 1920x530px_en.jpg"

import BoatPrivativoTabletPt from "../../../assets/img/banners/banners-boat-privativo/tablet_ 1024x480px.jpg"
import BoatPrivativoTabletEs from "../../../assets/img/banners/banners-boat-privativo/tablet_ 1024x480px_es.jpg"
import BoatPrivativoTabletEn from "../../../assets/img/banners/banners-boat-privativo/tablet_ 1024x480px_en.jpg"

import BoatPrivativoMobilePt from "../../../assets/img/banners/banners-boat-privativo/mobile_ 768x640px.jpg"
import BoatPrivativoMobileEs from "../../../assets/img/banners/banners-boat-privativo/mobile_ 768x640px_es.jpg"
import BoatPrivativoMobileEn from "../../../assets/img/banners/banners-boat-privativo/mobile_ 768x640px_en.jpg"

import rioTerraMarDesktopPT from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarPT.jpg';
import rioTerraMarDesktopEN from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarEN.jpg';
import rioTerraMarDesktopES from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarESP.jpg';

import rioTerraMarMobilePT from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarPT-mobile.jpg';
import rioTerraMarMobileEN from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarEN-mobile.jpg';
import rioTerraMarMobileES from '../../../assets/img/banners-rio-por-terra-e-mar/rioporterraemarESP-mobile.jpg';

import afiliadoDesktopPt from '../../../assets/img/afiliado/DesktopPt.png';
import afiliadoDesktopEn from '../../../assets/img/afiliado/DesktopEn.png';
import afiliadoDesktopEs from '../../../assets/img/afiliado/DesktopEs.png';

import afiliadoMobilePt from '../../../assets/img/afiliado/MobilePt.png';
import afiliadoMobileEn from '../../../assets/img/afiliado/MobileEn.png';
import afiliadoMobileEs from '../../../assets/img/afiliado/MobileEs.png';

import afiliadoTabletPt from '../../../assets/img/afiliado/TabletPt.png';
import afiliadoTabletEn from '../../../assets/img/afiliado/TabletEn.png';
import afiliadoTabletEs from '../../../assets/img/afiliado/TabletEs.png';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BannerHome.scss";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from "react-router-dom";

export default class BannerHome extends Component {
  render() {
    const settings = {
      /* dots: true, */
      draggable: false,
      useTransform: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1500,
      autoplaySpeed: 5500,
      cssEase: "ease-out",
    };

    const typeDevice = window.innerWidth < 480 ? "mobile" : "desktop";
    // const typeDevice = window.innerWidth >= 1025 ? "desktop" : window.innerWidth >= 768 ? "tablet" : "mobile";

    return (
      <div className="bg-main-banner">
        <Slider {...settings}>


          {/* <Link
            to={
              i18next.language === "pt"
                ? 'tour/rio-boat-tour-noturno'
                : i18next.language === "en"
                  ? 'tour/rio-boat-tour-by-night'
                  : i18next.language === "es"
                    ? 'tour/rio-boat-tour-nocturno'
                    : 'tour/rio-boat-tour-noturno'
            }
          > */}


          {/* Banner Afiliados */}

          <Link
            to='/afiliados'
          >

            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? afiliadoDesktopPt
                          : i18next.language === "en"
                            ? afiliadoDesktopEn
                            : i18next.language === "es"
                              ? afiliadoDesktopEs
                              : afiliadoDesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )
                  //  : typeDevice === "tablet"? (
                  //   <img
                  //     className="p-0 m-0"
                  //     src={
                  //       i18next.language === "pt"
                  //         ? afiliadoTabletPt
                  //         : i18next.language === "en"
                  //           ? afiliadoTabletEn
                  //           : i18next.language === "es"
                  //             ? afiliadoTabletEs
                  //             : afiliadoTabletPt
                  //     }
                  //     alt="default banner"
                  //     loading="lazy"
                  //   />
                  // )
                   : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? afiliadoMobilePt
                          : i18next.language === "en"
                            ? afiliadoMobileEn
                            : i18next.language === "es"
                              ? afiliadoMobileEs
                              : afiliadoMobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link>
          {/* Banner Afiliados */}


           {/* Banner Boat Privativo */}
          <a
            href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_INFO_WHATSAPP?.replaceAll("+55", "55").replaceAll(" ", "").replaceAll("-", "").replaceAll("(21)", "21")}`}
          >
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? BoatPrivativoDesktopPt
                          : i18next.language === "en"
                            ? BoatPrivativoDesktopEn
                            : i18next.language === "es"
                              ? BoatPrivativoDesktopEs
                              : BoatPrivativoDesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) :
                  // typeDevice === "tablet" ? 
                  // (
                  //   <img
                  //     className="p-0 m-0"
                  //     src={
                  //       i18next.language === "pt"
                  //         ? BoatPrivativoTabletPt
                  //         : i18next.language === "en"
                  //           ? BoatPrivativoTabletEn
                  //           : i18next.language === "es"
                  //             ? BoatPrivativoTabletEs
                  //             : BoatPrivativoTabletPt
                  //     }
                  //     alt="default banner"
                  //     loading="lazy"
                  //   />
                  // ):
                   (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? BoatPrivativoMobilePt
                          : i18next.language === "en"
                            ? BoatPrivativoMobileEn
                            : i18next.language === "es"
                              ? BoatPrivativoMobileEs
                              : BoatPrivativoMobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </a>         
          {/* Banner Boat Privativo */}
          

          {/* Banner Pretópolis cidade imperial */}
          {/* <Link
            to={
              i18next.language === "pt"
                ? 'tour/petropolis-a-cidade-imperial'
                : i18next.language === "en"
                  ? 'tour/petropolis-the-imperial-city'
                  : i18next.language === "es"
                    ? 'tour/petropolis-la-ciudad-imperial'
                    : 'tour/petropolis-a-cidade-imperial'
            }
          >
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? petropolisDesktopPt
                          : i18next.language === "en"
                            ? petropolisDesktopEn
                            : i18next.language === "es"
                              ? petropolisDesktopEs
                              : petropolisDesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                        ? petropolisMobilePt
                        : i18next.language === "en"
                          ? petropolisMobileEn
                          : i18next.language === "es"
                            ? petropolisMobileEs
                            : petropolisMobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link> */}
          {/* Banner Pretópolis cidade imperial */}


          {/* Banner Rio por terra e mar */}
          <Link
            to={
              i18next.language === "pt"
                ? 'tour/cristo-sunset-boat'
                : i18next.language === "en"
                  ? 'tour/christ-sunset-boat'
                  : i18next.language === "es"
                    ? 'tour/cristo-atardecer-barco'
                    : 'tour/cristo-sunset-boat'
            }
          >
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? rioTerraMarDesktopPT
                          : i18next.language === "en"
                            ? rioTerraMarDesktopEN
                            : i18next.language === "es"
                              ? rioTerraMarDesktopES
                              : rioTerraMarDesktopPT
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                        ? rioTerraMarMobilePT
                        : i18next.language === "en"
                          ? rioTerraMarMobileEN
                          : i18next.language === "es"
                            ? rioTerraMarMobileES
                            : rioTerraMarMobilePT
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link>
          {/* Banner Rio por terra e mar */}


          <Link to={"/transfer"}>
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? Banner03C2DesktopPt
                          : i18next.language === "en"
                            ? Banner03C2DesktopEn
                            : i18next.language === "es"
                              ? Banner03C2DesktopEs
                              : Banner03C2DesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? Banner03C2MobilePt
                          : i18next.language === "en"
                            ? Banner03C2MobileEn
                            : i18next.language === "es"
                              ? Banner03C2MobileEs
                              : Banner03C2MobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link>          


          <Link
            to={
              i18next.language === "pt"
                ? 'tour/rio-boat-tour-carioca-pt'
                : i18next.language === "en"
                  ? 'tour/rio-boat-tour-carioca-en'
                  : i18next.language === "es"
                    ? 'tour/rio-boat-tour-carioca-es'
                    : 'tour/rio-boat-tour-carioca-pt'
            }
          >
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? rioBoatTourDeskPt
                          : i18next.language === "en"
                            ? rioBoatTourDeskEn
                            : i18next.language === "es"
                              ? rioBoatTourDeskEs
                              : rioBoatTourDeskPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? rioBoatTourMobPt
                          : i18next.language === "en"
                            ? rioBoatTourMobEn
                            : i18next.language === "es"
                              ? rioBoatTourMobEs
                              : rioBoatTourMobPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link>

          {/* Banner Pretópolis beer tour */}
          {/* <Link
            to={
              i18next.language === "pt"
                ? 'tour/petropolis-a-cidade-imperial'
                : i18next.language === "en"
                  ? 'tour/petropolis-the-imperial-city'
                  : i18next.language === "es"
                    ? 'tour/petropolis-la-ciudad-imperial'
                    : 'tour/petropolis-a-cidade-imperial'
            }
          >
            <Container className="bg-secondary banner-home p-0 m-0" fluid>
              <Container className="p-0 m-0" fluid>
                <Row className="p-0 m-0">
                  {typeDevice === "desktop" ? (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                          ? tourCervejeiroDesktopPt
                          : i18next.language === "en"
                            ? tourCervejeiroDesktopEn
                            : i18next.language === "es"
                              ? tourCervejeiroDesktopEs
                              : tourCervejeiroDesktopPt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  ) : (
                    <img
                      className="p-0 m-0"
                      src={
                        i18next.language === "pt"
                        ? tourCervejeiroMobilePt
                        : i18next.language === "en"
                          ? tourCervejeiroMobileEn
                          : i18next.language === "es"
                            ? tourCervejeiroMobileEs
                            : tourCervejeiroMobilePt
                      }
                      alt="default banner"
                      loading="lazy"
                    />
                  )}
                </Row>
              </Container>
            </Container>
          </Link> */}
          {/* Banner Pretópolis beer tour */}          

          
        </Slider>
      </div>
    );
  }
}
