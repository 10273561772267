import i18next from 'i18next';
import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './infra/PrivateRoute';
import AboutUsAngra from './pages/AboutUs/AboutUsAngra';
import Category from './pages/Category/Category';

import CategoryCrossBondinhoTour from './pages/CategoryCrossBondinhoTour/CategoryCrossBondinhoTour';
import CategoryOrla from './pages/CategoryOrla/CategoryOrla';
import CategoryTest from './pages/CategoryTest/CategoryTest';

import PreLoader from './pages/PreLoader/PreLoader';
import CatTest from './pages/TestCat/CatTest';
import TestingComponents from './pages/TestingComponents/TestingComponents';
import TourList from './pages/TourList/TourList';
import TransferList from './pages/TransferList/TransferList';
import Affiliates from './pages/Affiliates/Affiliates';
import CategoryAbav from './pages/CategoryAbav/CategoryAbav';

const Home = React.lazy(() => import('./pages/Home/Home'));
const Tour = React.lazy(() => import('./pages/Tour/Tour'));
const Ticket = React.lazy(() => import('./pages/Ticket/Ticket'));
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'));

const ContactUs = React.lazy(() => import('./pages/ContactUs/ContactUs'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/AboutUs'));
const TermsOfUse = React.lazy(() => import('./pages/TermsOfUse/TermsOfUse'));
const TermsOfPurchase = React.lazy(() => import('./pages/TermsOfPurchase/TermsOfPurchase'));
const PrivacyNotice = React.lazy(() => import('./pages/PrivacyNotice/PrivacyNotice'));
const Cookies = React.lazy(() => import('./pages/Cookies/Cookies'));
const CommonQuestions = React.lazy(() => import('./pages/CommonQuestions/CommonQuestions'));
const Login = React.lazy(() => import('./pages/Login/Login'));
const RegisterUser = React.lazy(() => import('./pages/RegisterUser/RegisterUser'));
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const ProfilePassword = React.lazy(() => import('./pages/ProfilePassword/ProfilePassword'));
const ProfileResetPassword = React.lazy(() => import('./pages/ProfileResetPassword/ProfileResetPassword'));
const ProfileForgotPassword = React.lazy(() => import('./pages/ProfileForgotPassword/ProfileForgotPassword'));
const Confirmation = React.lazy(() => import('./pages/Confirmation/Confirmation'));
const MyReservations = React.lazy(() => import('./pages/MyReservations/MyReservations'));
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));


function Routes() {

    const [authentication, setAuthState] = useState({
        authenticated: false,
        initializing: true
    });

    useEffect(() => {
        if (window.location.href.split('.travel/')[1] === 'pt' || window.location.href.split('.travel/')[1] === 'pt/') {
            i18next.changeLanguage('pt');
            window.location.href = '/';
        } else if (window.location.href.split('.travel/')[1] === 'en' || window.location.href.split('.travel/')[1] === 'en/') {
            i18next.changeLanguage('en');
            window.location.href = '/';
        } else if (window.location.href.split('.travel/')[1] === 'es' || window.location.href.split('.travel/')[1] === 'es/') {
            i18next.changeLanguage('es');
            window.location.href = '/';
        } else if (window.location.href.includes('en/sabores-da-orla-flavors-of-the-shore-gastronomic-experience-along-the-rio-de-janeiro-shore&cod=PLO7029')) {
            i18next.changeLanguage('en');
            window.location.href = '/tour/gastronomic-experience-in-rio-de-janeiro';
        } else if (window.location.href.includes('es/sabores-da-orla-flavors-of-the-shore-gastronomic-experience-along-the-rio-de-janeiro-shore&cod=PLO7029')) {
            i18next.changeLanguage('es');
            window.location.href = '/tour/experiencia-gastronómica-en-río de-janeiro';
        } else if (window.location.href.includes('pt/sabores-da-orla-flavors-of-the-shore-gastronomic-experience-along-the-rio-de-janeiro-shore&cod=PLO7029')) {
            i18next.changeLanguage('pt');
            window.location.href = '/tour/experiencia-gastronomica-no-rio-de-janeiro';
        }

        const objAuth: any = localStorage.getItem('c2siteAuth');

        if (objAuth !== null) {
            setAuthState({
                authenticated: true,
                initializing: false
            });
        } else {
            setAuthState({
                authenticated: false,
                initializing: false
            });

        }

    }, [setAuthState]);

    if (authentication.initializing) {
        return <div><PreLoader /></div>;
    }

    return (
        <Suspense fallback={PreLoader}>
            <Switch>
                <Route path="/" exact ><Home /></Route>
                {/* <Route path="/code/:code" exact component={Home} /> */}
                <Route path="/tour/:slug" exact><Tour /></Route>
                <Route path="/ticket/:slug" exact><Ticket /></Route>

                <Route path="/fale-conosco" exact><ContactUs /></Route>
                <Route path="/sobre-nos" exact>
                    {
                        process.env.REACT_APP_ENVIRONMENT === "c2rio"
                        ?
                        <AboutUs />
                        :
                        <AboutUsAngra />
                    }
                </Route>
                <Route path="/afiliados" exact> <Affiliates /></Route>
                <Route path="/termos-e-condicoes" exact><TermsOfUse /></Route>
                <Route path="/termos-de-compra" exact><TermsOfPurchase /></Route>
                <Route path="/politica-de-privacidade" exact><PrivacyNotice /></Route>
                <Route path="/cookies" exact><Cookies /></Route>
                <Route path="/perguntas-frequentes" exact> <CommonQuestions /></Route>
                <Route path="/checkout" exact> <Checkout /> </Route>
                <Route path="/login" exact> <Login /></Route>
                <Route path="/atividades/:slug" exact><TourList /> </Route>
                {process.env.REACT_APP_ENVIRONMENT === "c2rio" && <Route path="/transfer" exact><TransferList /></Route>}
                <Route path="/cadastrar-usuario" exact> <RegisterUser /> </Route>
                <Route path="/nova-senha" exact> <ProfilePassword /> </Route>
                <Route path="/esqueci-senha" exact> <ProfileForgotPassword /> </Route>
                <Route path="/confirmacao-cadastro/:token" exact> <Confirmation /> </Route>
                <Route path="/resetar-senha/:token" exact> <ProfileResetPassword /> </Route>

                {/*Categorias*/}

                <Route path="/promocao" exact> <Category /> </Route>
                <Route path="/orla-rio" exact> <CategoryOrla /> </Route>
                <Route path="/cross-bondinho" exact> <CategoryCrossBondinhoTour /> </Route>
                <Route path="/abav-expo" exact> <CategoryAbav /> </Route>

                {/*Categorias*/}

                <PrivateRoute path="/minhas-atividades" exact isAuthenticated={authentication.authenticated} component={MyReservations} /> {/* <MyReservations /> </Route> */}
                <PrivateRoute path="/perfil" exact isAuthenticated={authentication.authenticated} component={Profile} />

                <Route path="/page-test" exact> <TestingComponents /> </Route>

                {process.env.NODE_ENV === 'development' && <Route path="/teste-categoria" exact > <CategoryTest /> </Route>}

                <Route path="/category-test/:slug" exact > <CatTest /> </Route>

                <Route path="/404" exact ><NotFound /></Route>

                {/* <Route exact component={NotFound} /> */}



                <Route exact >
                    <Redirect to="/404" />
                </Route>

            </Switch>
        </Suspense>
    );
}
export default Routes;