import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BannerHomeIntegrated from "../../components/Banners/BannerHomeIntegrated/BannerHomeIntegrated";
import ModalAlert from "../../components/Modal/ModalAlert/ModalAlert";
import CategoryTest from "../CategoryTest/CategoryTest";
import StoreHours from "../Home/components/StoreHours/StoreHours";
import i18next from "i18next";

function TestingComponents() {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [url, setUrl] = useState<string>('');
    const [modalList, setModalList] = useState<any>(null);
    const [lang, setLang] = useState<any>(i18next.language);
   
   console.log(i18next)
   
    return (
        <>
            <h2 className="text-center">Banners</h2>
            <BannerHomeIntegrated />
            <h2 className="text-center">Horário de Funcionamento</h2>
            <StoreHours />
            {/* <h2 className="text-center">Modal</h2>
            <div  className="text-center mb-3" >
                <Button onClick={()=> setModalShow(true)}>Abrir Modal</Button>
                <ModalAlert modalShow={modalShow} setModalShow={setModalShow} info={modalList?.length > 0 ? modalList[0] : null} url={url}/>
            </div> */}
            
            <h2 className="text-center">Categorias</h2>
            <CategoryTest />
        </>
    )
};

export default TestingComponents;