import React, { useState, useEffect, Key } from "react";

import api from "../../services/api";

import { Container, Row, Col } from "react-bootstrap";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import configData from "../../config/config.json";

import "./Category.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";


function Category() {
  //const [favorite, setFavorite] = useState<any>([]);  
  const [tourLists, setTourList] = useState<any>(null);
  //const [tourListSize, setTourListSize] = useState<any>(null);
  //const [pagination, setPagination] = useState<any>(5);
  //const [activeCarrousel, setActiveCarrousel] = useState<any>([false, false, false, false, false, false]); ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox
  const lng =
    i18next.language === "pt"
      ? "BR"
      : i18next.language === "en"
      ? "EN"
      : i18next.language === "es"
      ? "ES"
      : "BR";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState<any>(null);
  const [seeMore, setSeeMore] = useState<any>(5);
  const [preLoader, setPreLoader] = useState<any>(null);
  const [t] = useTranslation();

  //const categories = localStorage.getItem('popularCat') || '{}';

  /* function AddFavorite(index: any) {
        let auxFav:any = [];

        for( let i = 0; i < favorite.length; i++) {
            if ( i === index ) {
                auxFav.push(!favorite[i]);
            } else {
                auxFav.push(favorite[i]);
            }
        }
        
        setFavorite(auxFav);
    } */

  /* Scroll para os passeios */
  /* scroller.scrollTo('tour-list', {
        duration: 1500,
        delay: 0,
        smooth: true,
        offset: 0,
    }) */
  /* END - Scroll para os passeios */

  useEffect(() => {
    const url = window.location.pathname;
    const codeUrl = url;
    //setCity(codeUrl.split('-').join(' '))
    console.log(codeUrl);
    const GetProducts = async () => {
      try {
        const res = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/Products/GetProductsFromCategories`,
          {
            categoryCode: ["CAT-CA73396"],
            lang: lng,
          }
        );

        if (res.data.status !== 400) {
          setTourList(res.data.data);
          setValue({
            min: 0 /* Math.floor(res.data.data.minPrice) */,
            max: 1000 /* Math.ceil(data.data.maxPrice)  */,
          });
          setPreLoader(true);
        }
      } catch (error) {}
    };
    GetProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lng]);

  /* useEffect(() => {
        let aux: any = [];
        for ( let i = 0; i < tourLists?.length; i++) {
            aux.push(false)
        }
        setFavorite(aux);
    }, [tourLists]) */

  function HandleMore() {
    if (seeMore <= tourLists.length) {
      setSeeMore(seeMore + 5);
    } else {
      setSeeMore(5);
    }
  }

  if (tourLists !== null) {
    return (
      <>
        <main>
          <Header />
          {/* <BannerHome />
                    <TabSearchs /> */}
          <div className="bg-tour-right-default">
            <div className="bg-tour-left-default">
              <Container className="container-content bg-tour-list">
                <div className="container-fluid content-page px-0">
                  <div id="tour-list" className="container-fluid container-md">
                    <Row>
                      <Col lg={12} className="px-2 mt-3">
                        <div className="tour-info-city">
                          <Row className="align-items-center">
                            <Col md={12}>
                              <h1 className="city title-h1 activities">
                              {tourLists[0]?.eventLocation.replace(", RJ", "")} | {tourLists[0]?.categoryName.split("-")[0]}
                              </h1>
                              <h2 className="display-4 small mb-4 activities">
                                {tourLists.length !== undefined
                                  ? tourLists.length
                                  : 0}{" "}
                                atividades encontradas no {tourLists[0]?.eventLocation.split(",")[0]}
                              </h2>
                            </Col>
                            {/* <Col md={7}>
                                                            <div className='tour-order d-flex justify-content-md-end'>
                                                                <span className='d-none d-md-block'>Ordernar por:</span>
                                                                <Form.Select aria-label="Default select example">
                                                                    <option>Recomendados</option>
                                                                    <option value="1">Avaliações</option>
                                                                    <option value="2">Menor Preço</option>
                                                                    <option value="3">Maior Preço</option>
                                                                </Form.Select>
                                                            </div>
                                                            <div className='tour-make-things d-block d-md-none'>
                                                                <p className='city'>Coisas para fazer - <span>{tourLists[0]?.categoryName.split("-")[0]}</span></p>
                                                            </div>
                                                        </Col> */}
                          </Row>
                        </div>
                        <div className="d-flex justify-content-end">
                          {/* Reponsividade */}
                          {/* <div className="d-block d-lg-none">
                                            <ModalDefault
                                                name={'sliders-h'}
                                                center={'yes'}
                                                classBody="filter-mobile"
                                            >
                                                <FilterTour
                                                    search={props.search}
                                                    value={value}
                                                    setTourList={setTourList}
                                                    activeCarrousel={activeCarrousel}
                                                    setActiveCarrousel={setActiveCarrousel}
                                                />
                                            </ModalDefault>
                                        </div> */}
                          {/* Reponsividade */}
                        </div>
                        {tourLists.length > 0
                          ? tourLists
                              .slice(0, seeMore)
                              .map((product: any, index: Key) => {
                                const ratingTotal = product.stars;
                                let rating: any;
                                let arrayStars: any = [];
                                if (ratingTotal !== null) {
                                  if (ratingTotal > 0 && ratingTotal <= 0.75) {
                                    rating = 0.5;
                                  } else if (
                                    ratingTotal > 0.76 &&
                                    ratingTotal <= 1.25
                                  ) {
                                    rating = 1;
                                  } else if (
                                    ratingTotal > 1.26 &&
                                    ratingTotal <= 1.75
                                  ) {
                                    rating = 1.5;
                                  } else if (
                                    ratingTotal > 1.76 &&
                                    ratingTotal <= 2.25
                                  ) {
                                    rating = 2;
                                  } else if (
                                    ratingTotal > 2.26 &&
                                    ratingTotal <= 2.75
                                  ) {
                                    rating = 2.5;
                                  } else if (
                                    ratingTotal > 2.76 &&
                                    ratingTotal <= 3.25
                                  ) {
                                    rating = 3;
                                  } else if (
                                    ratingTotal > 3.26 &&
                                    ratingTotal <= 3.75
                                  ) {
                                    rating = 3.5;
                                  } else if (
                                    ratingTotal > 3.76 &&
                                    ratingTotal <= 4.25
                                  ) {
                                    rating = 4;
                                  } else if (
                                    ratingTotal > 4.26 &&
                                    ratingTotal <= 4.75
                                  ) {
                                    rating = 4.5;
                                  } else if (
                                    ratingTotal > 4.76 &&
                                    ratingTotal <= 5
                                  ) {
                                    rating = 5;
                                  }

                                  for (var i: any = 0; i < 5; i = i + 0.5) {
                                    if (i < rating) {
                                      arrayStars.push(1);
                                    } else {
                                      arrayStars.push(0);
                                    }
                                  }

                                  let aux: any = [];

                                  for (var j: any = 0; j < 10; j = j + 2) {
                                    if (
                                      arrayStars[j] === 1 &&
                                      arrayStars[j + 1] === 1
                                    ) {
                                      aux.push(1);
                                    } else if (
                                      arrayStars[j] === 1 &&
                                      arrayStars[j + 1] === 0
                                    ) {
                                      aux.push(0.5);
                                    } else if (
                                      arrayStars[j] === 0 &&
                                      arrayStars[j + 1] === 0
                                    ) {
                                      aux.push(0);
                                    }
                                  }

                                  arrayStars = aux;
                                } else {
                                  <></>;
                                }
                                /* INICIO DO PRELOADER*/
                                if (preLoader !== null) {
                                    return (
                                        <div
                                          key={index}
                                          className="card card-tour-category p-3 mb-4 bg-tourlist-page"
                                        >
                                          <Link
                                              to={
                                                product.productType === "TOUR"
                                                  ? `/tour/${product.productSlug}`
                                                  : `/ticket/${product.productSlug}`
                                              }
                                            >
                                          {/* <Link key={index} to={product.productType === 'TOUR' ? `/tour/${product.productSlug}` : `/ticket/${product.productSlug}`} className="card card-tour p-3 mb-4"> */}
                                          <Row className="m-0">
                                            
                                            <div
                                              className="col-5 col-md-4 px-0"
                                              style={{ overflow: "hidden" }}
                                            >                                                
                                              <span
                                                className="badge rounded position-absolute badge-primary py-2 px-3 text-uppercase"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  key={index}
                                                  icon={[
                                                    "fal",
                                                    product.productType === "TOUR"
                                                      ? "map"
                                                      : "ticket-alt",
                                                  ]}
                                                  size="2x"
                                                  style={{
                                                    margin: "0px 5px 0 0px",
                                                    fontSize: "1em",
                                                  }}
                                                />
                                                {product.productType === "TOUR"
                                                  ? "Tours"
                                                  : "Tickets"}
                                              </span>
                                              <img
                                                src={
                                                  product.productType === "TOUR"
                                                    ? configData.SERVER_TESTE +
                                                      "images-products/medium_" +
                                                      `${product.productImg}`
                                                    : configData.SERVER_TESTE +
                                                      "img_tickets/products/" +
                                                      `${product.productImg}`
                                                }
                                                className="card-img card-img-category"
                                                alt="..."
                                              />
                                            </div>
                                            <div className="col-7 col-md-8 d-flex flex-column justify-content-between">
                                              <div>                                                
                                                  <h5 className="tour-name">
                                                    {product.productName}
                                                  </h5>                                                
                                                <div className="stars">
                                                  <span>
                                                    {arrayStars.length > 0
                                                      ? arrayStars.map(
                                                          (array: any, index: Key) => {
                                                            return array === 1 ||
                                                              array === 0 ? (
                                                              <FontAwesomeIcon
                                                                key={index}
                                                                icon={["fas", "star"]}
                                                                size="1x"
                                                                style={{
                                                                  margin: "0px 5px 5px 0px",
                                                                  color:
                                                                    array === 1
                                                                      ? "#ffC60A"
                                                                      : "#707070",
                                                                }}
                                                              />
                                                            ) : (
                                                              <FontAwesomeIcon
                                                                key={index}
                                                                icon={["fad", "star-half"]}
                                                                size="1x"
                                                                style={{
                                                                  margin: "0px 5px 5px 0px",
                                                                  color:
                                                                    array === 0.5
                                                                      ? "#ffC60A"
                                                                      : "#707070",
                                                                }}
                                                              />
                                                            );
                                                          }
                                                        )
                                                      : ""}
                                                  </span>
                                                </div>
                                                {/* <div className="small text-muted my-1">
                                                                                <span><strong>{t("tourList.suppliers")}:</strong> {product.supplierFantasyName}</span>
                                                                            </div> */}
                                                {/* <p className="small text-muted">
                                                  <i
                                                    className="far fa-clock"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {t("tourList.duration")}:
                                                  {" " +
                                                    product.durationHours +
                                                    ":" +
                                                    product.durationMinutes +
                                                    " horas"}
                                                </p> */}
                                                <div
                                                  className="text-tour-info mb-1 d-none d-sm-block">
                                                   <ul>
                                                   {product.productIncludeItem.map((item:any, index:any)=>{
                                                    return( 
                                                        <li>{item}</li>                                                     
                                                      )
                                                    })} 
                                                    </ul> 
                                                  </div>
                                              </div>
                                              <div>
                                                <span className="small text-muted">
                                                  {t("tourList.from")}:
                                                </span>
                                                <div>
                                                  <span className="h4">
                                                    {"R$ " +
                                                      (
                                                        (1 - product.percDesc / 100) *
                                                        product.price
                                                      )
                                                        .toFixed(2)
                                                        .replace(".", ",")}
                                                  </span>
                                                  {/* (1 - (options[i].tarif?.percDesc / 100)) * options[i].tarif?.price */}
                                                </div>
                                              </div>
                                            </div>
                                          
                                          </Row>
                                          </Link>
                                        </div>
                                      );
                                } else {
                                  return (
                                    <div
                                      key={index}
                                      className="card card-tour p-3 mb-4"
                                    >
                                      <Row>
                                        <Col
                                          md={4}
                                          className="px-0"
                                          style={{ overflow: "hidden" }}
                                        >
                                          <div
                                            className="card-img card-img-custom animated-background"
                                            style={{ width: "100%" }}
                                          ></div>
                                        </Col>
                                        <Col
                                          md={8}
                                          className="card-tour-text pr-0 pl-custom"
                                        >
                                          <Link
                                            to={
                                              product.productType === "TOUR"
                                                ? `/tour/${product.productSlug}`
                                                : `/ticket/${product.productSlug}`
                                            }
                                          >
                                            <Row>
                                              <Col md={8}>
                                                <div>
                                                  <div
                                                    className="tour-name animated-background"
                                                    style={{
                                                      height: "56px",
                                                      width: "100%",
                                                      marginBottom: "8px",
                                                    }}
                                                  ></div>

                                                  <div
                                                    className="small text-muted d-none d-md-flex animated-background"
                                                    style={{
                                                      height: "24px",
                                                      width: "100%",
                                                      marginBottom: "8px",
                                                    }}
                                                  ></div>
                                                  <div
                                                    className="text-tour-info animated-background"
                                                    style={{
                                                      height: "110px",
                                                      width: "100%",
                                                      marginBottom: "16px",
                                                    }}
                                                  ></div>
                                                </div>
                                              </Col>
                                              <Col md={4} className="px-md-0">
                                                <div
                                                  className="bg-stars animated-background"
                                                  style={{
                                                    height: "68px",
                                                    width: "100%",
                                                  }}
                                                ></div>
                                              </Col>
                                            </Row>
                                          </Link>
                                        </Col>
                                      </Row>
                                    </div>
                                  );
                                }
                                /* FIM DO PRELOADER*/
                              })
                          : ""}
                        {tourLists.length > 5 ? (
                          <div className="btn-see-more">
                            <button
                              className="btn btn-outline-primary"
                              onClick={HandleMore}
                            >
                              Veja mais atividades
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <Footer />
        </main>
      </>
    );
  } else {
    return <></>;
  }
}

export default Category;
