/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
import React, { useState, useEffect, Key } from "react";
import { Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Link } from "react-router-dom";

import "./TourList.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import configData from "../../config/config.json";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BannerHome from "../../components/Banners/BannerHome/BannerHome";
import FilterTourCarrousel from "./components/FilterTourCarrousel/FilterTourCarrousel";
import FilterTour from "./components/FilterTour/FilterTour";
import TabSearchs from "../Home/components/TabSearchs/TabSearchs";
import FilterModal from "./components/FilterModal/FilterModal";
import Pagination from "../../components/Pagination/Pagination";
import { Helmet } from "react-helmet";
import BannerHomeAngra from "../../components/Banners/BannerHomeAngra/BannerHomeAngra";

function TourList() {
  const [t] = useTranslation();

  const [search, setSearch] = useState<any>(null);
  const [urlSearch, setUrlSearch] = useState<any>("");

  const [totalRows, setTotalRows] = useState<any>(0);
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  const [tourLists, setTourList] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(5);
  const [activeCarrousel, setActiveCarrousel] = useState<any>([
    false,
    false,
    false,
    false,
    false,
    false,
  ]); ///auxilia para ativar o filtro no carrousel, quando o click ocorre no checkbox
  const lng =
    i18next.language === "pt"
      ? "BR"
      : i18next.language === "en"
        ? "EN"
        : i18next.language === "es"
          ? "ES"
          : "BR";
  const [value, setValue] = useState<any>({ min: 0, max: 1000 });

  useEffect(() => {
    let searchElement: any = window.location.href.split("/");
    searchElement = searchElement[searchElement.length - 1];
    searchElement = searchElement.split('?')[0];
    setUrlSearch(searchElement);
    searchElement = searchElement.split("-").join(" ");
    setSearch(searchElement);
  }, []);

  /* const paginateFoward = () => {
        setPagination(pagination + 5);
    }

    const paginateBack = () => {
        setPagination(pagination - 5);
    } */

  /* useEffect(() => {
        if (tourLists?.length > 0) {
            setTourListSize(tourLists.length)
        } else {
            setTourListSize(0)
        }

    }, [tourLists]) */

  const ScrollTop = () => {
    window.scrollTo(0, 500);
  }

  useEffect(() => {
    ScrollTop();
  }, [pageCount])

  return (
    <>
      <Helmet>
        <html lang={i18next.language} />
        <title>Atividades - {search === null ? " " : search}</title>

        <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

        {/* Essential META Tags */}
        <meta property="og:title" content={"Atividades -" + search} />
        <meta property="og:type" content="TravelAgency" />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_IMG_DEST}`}
        />
        <meta
          property="og:url"
          content={process.env.REACT_APP_SERVER_URL + "atividades/" + urlSearch}
        />

        {/* Non-Essential, But Recommended */}
        <meta
          property="og:description"
          content={"Página de atividades para " + search}
        />
        <meta property="og:site_name" content={"Atividades -" + search} />

        {/* Non-Essential, But Required for Analytics */}
        {/* <meta property="fb:app_id" content="your_app_id" /> */}

        <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
        <link
          rel="canonical"
          href={process.env.REACT_APP_SERVER_URL + "atividades/" + urlSearch}
        />
      </Helmet>
      <Header />
      <div className="content-header">
      {
          process.env.REACT_APP_ENVIRONMENT === "c2rio"
            ?
            <BannerHome />
            :
            <BannerHomeAngra />
        }
        <div className="container-fluid my-0 my-md-4">
          <div className="container-fluid container-md nav-pills-c2">
            <TabSearchs setSearch={setSearch} />
          </div>
        </div>
      </div>
      {search !== null ? (
        <>
          <div id="tour-list" className="bg-tourlist-page container-fluid content-page px-0">
            <div className="container-fluid container-md py-4 py-md-0">
              <Row>
                <div className="col-12 col-lg-3 d-none d-lg-block">
                  <div className="card card-filter mb-4">
                    <FilterTour
                      search={search}
                      value={value}
                      setTourList={setTourList}
                      activeCarrousel={activeCarrousel}
                      setActiveCarrousel={setActiveCarrousel}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      rowsPerPage={rowsPerPage}
                      setTotalRows={setTotalRows}
                      setLoading={setLoading}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-9 px-2">
                  <h1 className="title-h1 activities" style={{ overflow: 'hidden' }}>Atividades - {search}</h1>
                  <h2 className="display-4 small mb-4">
                    {totalRows} atrações turísticas no{" "}
                    <span className="activities">{search}</span>
                  </h2>
                  <div className="card card-filter-top mb-2">
                    <FilterTourCarrousel activeCarrousel={activeCarrousel} />
                  </div>
                  <div className="d-flex justify-content-end">
                    {/* Reponsividade */}
                    <div className="d-block d-lg-none">
                      <FilterModal
                        name={"sliders-h"}
                        center={"yes"}
                        classBody="filter-mobile"
                      >
                        <FilterTour
                          search={search}
                          value={value}
                          setTourList={setTourList}
                          activeCarrousel={activeCarrousel}
                          setActiveCarrousel={setActiveCarrousel}
                          pageCount={pageCount}
                          setPageCount={setPageCount}
                          rowsPerPage={rowsPerPage}
                          setTotalRows={setTotalRows}
                          setLoading={setLoading}
                        />
                      </FilterModal>
                    </div>
                    {/* Reponsividade */}
                  </div>
                  {tourLists?.length > 0 && loading === false
                    ? tourLists /* .slice(pagination - 5, pagination) */
                      .map((product: any, index: Key) => {
                        const ratingTotal = product.stars;
                        let rating: any;
                        let arrayStars: any = [];
                        if (ratingTotal !== null) {
                          if (ratingTotal > 0 && ratingTotal <= 0.75) {
                            rating = 0.5;
                          } else if (
                            ratingTotal > 0.76 &&
                            ratingTotal <= 1.25
                          ) {
                            rating = 1;
                          } else if (
                            ratingTotal > 1.26 &&
                            ratingTotal <= 1.75
                          ) {
                            rating = 1.5;
                          } else if (
                            ratingTotal > 1.76 &&
                            ratingTotal <= 2.25
                          ) {
                            rating = 2;
                          } else if (
                            ratingTotal > 2.26 &&
                            ratingTotal <= 2.75
                          ) {
                            rating = 2.5;
                          } else if (
                            ratingTotal > 2.76 &&
                            ratingTotal <= 3.25
                          ) {
                            rating = 3;
                          } else if (
                            ratingTotal > 3.26 &&
                            ratingTotal <= 3.75
                          ) {
                            rating = 3.5;
                          } else if (
                            ratingTotal > 3.76 &&
                            ratingTotal <= 4.25
                          ) {
                            rating = 4;
                          } else if (
                            ratingTotal > 4.26 &&
                            ratingTotal <= 4.75
                          ) {
                            rating = 4.5;
                          } else if (ratingTotal > 4.76 && ratingTotal <= 5) {
                            rating = 5;
                          }

                          for (var i: any = 0; i < 5; i = i + 0.5) {
                            if (i < rating) {
                              arrayStars.push(1);
                            } else {
                              arrayStars.push(0);
                            }
                          }

                          let aux: any = [];

                          for (var j: any = 0; j < 10; j = j + 2) {
                            if (
                              arrayStars[j] === 1 &&
                              arrayStars[j + 1] === 1
                            ) {
                              aux.push(1);
                            } else if (
                              arrayStars[j] === 1 &&
                              arrayStars[j + 1] === 0
                            ) {
                              aux.push(0.5);
                            } else if (
                              arrayStars[j] === 0 &&
                              arrayStars[j + 1] === 0
                            ) {
                              aux.push(0);
                            }
                          }

                          arrayStars = aux;
                        } else {
                          <></>;
                        }

                        return (
                          <div
                            key={index}
                            className="card card-tour p-3 mb-4"
                          >
                            {/* <Link key={index} to={product.typeProduct === 'TOUR' ? `/tour/${product.productSlug}` : `/ticket/${product.productSlug}`} className="card card-tour p-3 mb-4"> */}
                            <Row>
                              <div
                                className="col-5 col-md-4 px-1"
                                style={{ overflow: "hidden" }}
                              >
                                <span
                                  className="badge rounded position-absolute badge-primary py-2 px-3 text-uppercase"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    key={index}
                                    icon={[
                                      "fal",
                                      product.typeProduct === "TOUR"
                                        ? "map"
                                        : "ticket-alt",
                                    ]}
                                    size="2x"
                                    style={{
                                      margin: "0px 5px 0 0px",
                                      fontSize: "1em",
                                    }}
                                  />
                                  {product.typeProduct === "TOUR"
                                    ? "Tours"
                                    : "Tickets"}
                                </span>
                                <img
                                  src={
                                    product.typeProduct === "TOUR"
                                      ? process.env.REACT_APP_SERVER_URL_IMG +
                                      "images-products/medium_" +
                                      `${product.productImg}`
                                      : configData.SERVER_TESTE +
                                      "img_tickets/products/" +
                                      `${product.productImg}`
                                  }
                                  className="card-img card-img-custom"
                                  alt="..."
                                />
                              </div>
                              <div className="col-7 col-md-8 d-flex flex-column justify-content-between">
                                <div>
                                  <Link
                                    to={
                                      product.typeProduct === "TOUR"
                                        ? `/tour/${product.productSlug}`
                                        : `/ticket/${product.productSlug}`
                                    }
                                  >
                                    <h5 className="tour-name">
                                      {product.productName}
                                    </h5>
                                  </Link>
                                  <div className="stars">
                                    <span>
                                      {arrayStars.length > 0
                                        ? arrayStars.map(
                                          (array: any, index: Key) => {
                                            return array === 1 ||
                                              array === 0 ? (
                                              <FontAwesomeIcon
                                                key={index}
                                                icon={["fas", "star"]}
                                                size="1x"
                                                style={{
                                                  margin: "0px 5px 5px 0px",
                                                  color:
                                                    array === 1
                                                      ? "#ffC60A"
                                                      : "#707070",
                                                }}
                                              />
                                            ) : (
                                              <FontAwesomeIcon
                                                key={index}
                                                icon={["fad", "star-half"]}
                                                size="1x"
                                                style={{
                                                  margin: "0px 5px 5px 0px",
                                                  color:
                                                    array === 0.5
                                                      ? "#ffC60A"
                                                      : "#707070",
                                                }}
                                              />
                                            );
                                          }
                                        )
                                        : ""}
                                    </span>
                                  </div>
                                  {/* <div className="small text-muted my-1">
                                                                    <span><strong>{t("tourList.suppliers")}:</strong> {product.supplierFantasyName}</span>
                                                                </div> */}
                                  <p className="small text-muted">
                                    <i
                                      className="far fa-clock"
                                      aria-hidden="true"
                                    ></i>
                                    {t("tourList.duration")}:
                                    {" " +
                                      product.durationHours +
                                      ":" +
                                      product.durationMinutes +
                                      " horas"}
                                  </p>
                                  <div
                                    className="text-tour-info mb-1 d-none d-sm-block"
                                    dangerouslySetInnerHTML={{
                                      __html: product.productInfo,
                                    }}
                                  ></div>
                                </div>
                                <div>
                                  <span className="small text-muted">
                                    {t("tourList.from")}:
                                  </span>
                                  <div>
                                    <span className="h4">
                                      {"R$ " +
                                        (
                                          (1 - product.percDesc / 100) *
                                          product.price
                                        )
                                          .toFixed(2)
                                          .replace(".", ",")}
                                    </span>
                                    {/* (1 - (options[i].tarif?.percDesc / 100)) * options[i].tarif?.price */}
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        );
                      })
                    : loading === true
                      ? [0, 0, 0, 0, 0].map((elem: any, index: Key) => {
                        return (
                          <div className="card card-tour p-3 mb-4" key={index}>
                            <Row>
                              <div
                                className="col-5 col-md-4"
                                style={{ overflow: "hidden" }}
                              >
                                <div className="img animated-background h-100"></div>
                              </div>
                              <div className="col-7 col-md-8">
                                <div
                                  className="tour-name animated-background mb-2"
                                  style={{ height: "40px", width: "100%" }}
                                ></div>
                                <div
                                  className="stars animated-background mb-2"
                                  style={{ height: "20px", width: "50%" }}
                                ></div>
                                <div
                                  className="duration animated-background mb-2"
                                  style={{ height: "20px", width: "50%" }}
                                ></div>
                                <div
                                  className="descriptions animated-background mb-2"
                                  style={{ height: "70px", width: "100%" }}
                                ></div>
                                <div
                                  className="price animated-background mb-2"
                                  style={{ height: "20px", width: "50%" }}
                                ></div>
                                <div
                                  className="button animated-background"
                                  style={{ height: "40px", width: "30%" }}
                                ></div>
                              </div>
                            </Row>
                          </div>
                        );
                      })
                      :
                      <>
                        <h3 className='my-4 text-center'>Nenhuma atividade encontrada</h3>
                      </>
                  }

                  <Pagination
                    totalRows={totalRows}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    selectNumberRows="yes"
                  />
                  {/* <div className="d-flex justify-content-end">
                                            {pagination / 5 > 1 ? <button className="paginate-btn" onClick={paginateBack}>{"<"}</button> : ''}
                                            <span>Página {pagination / 5} . Mostranto ({pagination - 5} - {pagination}) de {tourListSize}</span>
                                            {(pagination / 5 < (tourListSize / 5)) ? <button className="paginate-btn" onClick={paginateFoward}>{">"}</button> : ''}
                                        </div> */}
                </div>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <Footer />
    </>
  );
}

export default TourList;
