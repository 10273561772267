import React, { useState, useEffect } from "react";

import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";

import Form from "react-bootstrap/Form";
import GetBanks from "../../../../../../components/C2Points/GetBanks";
// import ReCAPTCHA from "react-google-recaptcha";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import ModalPrivacy from "../../../../../../components/Modal/ModalPrivacy/ModalPrivacy";
import PrivacyTerms from "../../../../../PrivacyTerms/PrivacyTerms";
import TermsUse from "../../../../../TermsUse/TermsUse";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propForm {
  action: any;
  back: any;
  supplierControlObject: any;
  setModalSuccess?: any;
  loading?: any;
  isRecaptcha?: any;
  setIsRecaptcha?: any;
}

const FormBank: React.FC<propForm> = ({
  action,
  back,
  supplierControlObject,
  setModalSuccess,
  loading,
  isRecaptcha,
  setIsRecaptcha,
}: propForm) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [privacy, setPrivacy] = useState<any>(false);
  const [lng, setLng] = useState<any>("pt");

  const { t } = useTranslation();
  const validated = false;

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      codBank: supplierControlObject?.codBank
        ? {
            id: supplierControlObject.codBank,
            description: supplierControlObject.codBankDesc,
          }
        : "",
      accountType: supplierControlObject
        ? supplierControlObject.accountType
        : "1",
      agency: supplierControlObject ? supplierControlObject.agency : "",
      digitAgency: supplierControlObject
        ? supplierControlObject.digitAgency
        : "",
      account: supplierControlObject ? supplierControlObject.account : "",
      digitAccount: supplierControlObject
        ? supplierControlObject.digitAccount
        : "",
      checkPolice: supplierControlObject
        ? supplierControlObject.checkPolice
        : "",
    },
  });

  const accountType = watch(`accountType`, "1");

  const onSubmit = (data: any) => {
    // if (isRecaptcha !== null) {
      data.codBankDesc = data.codBank.description;
      data.codBank = data.codBank.id;
      //data.digitAgency = undefined;
      data.digitAccount = Number(data.digitAccount);

      setModalSuccess(true);
      action(data);
    // } else {
    //   alert("Por favor, selecione o reCAPTCHA se deseja prosseguir");
    // }
  };
  console.log(i18next.language);

  // const renderCaptcha = () => {
  //   return (
  //     <ReCAPTCHA
  //       sitekey={`${process.env.REACT_APP_RECAPTCHA_SITEKEY}`}
  //       onChange={handleRacaptcha}
  //       size="normal"
  //       id="ReCAPTCHA"
  //       hl={i18next.language}
  //     />
  //   );
  // };
  // useEffect(() => {
  //   async function reloadReacptcha() {
  //     setLng(i18next.language);
  //     renderCaptcha();
  //   }

  //   reloadReacptcha();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [i18next.language]);

  // function handleRacaptcha(value: any) {
  //   setIsRecaptcha(value);
  // }

  return (
    <>
      <div>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="false"
        >
          <Row>
            <Form.Group
              as={Col}
              md={6}
              controlId="formResourcesType"
              className="mb-3"
            >
              <Form.Label>{t("affiliates.formBank")}</Form.Label>
              <Controller
                control={control}
                name="codBank"
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                }}
                render={({ field }: any) => (
                  <GetBanks
                    propsField={field}
                    propsErrors={errors}
                    propsLabel="Banco"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="codBank"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md={6}
              controlId="formVehiclesType"
              className="mb-3"
            >
              <Form.Label>{t("affiliates.formAccountType")}</Form.Label>
              <Controller
                control={control}
                name="accountType"
                defaultValue={accountType}
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                }}
                render={({ field }: any) => (
                  <Form.Select
                    {...field}
                    aria-invalid={errors?.accountType ? "true" : ""}
                    label="Categoria"
                    as="select"
                    variant="standard"
                    margin="normal"
                    required
                  >
                    {/* <option value="" selected disabled>Selecione</option> */}
                    <option value="1" selected>
                      Corrente
                    </option>
                    <option value="2">Poupança</option>
                  </Form.Select>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="accountType"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group
              as={Col}
              sm={4}
              controlId="formResourcesType"
              className="mb-3"
            >
              <Form.Label>{t("affiliates.formAgency")}</Form.Label>
              <Controller
                control={control}
                name="agency"
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                  pattern: {
                    value: /[0-9]{3}/,
                    message: t("affiliates.formError"),
                  },
                }}
                render={({ field }: any) => (
                  <Form.Control
                    {...field}
                    aria-invalid={errors?.agency ? "true" : ""}
                    variant="standard"
                    margin="normal"
                    autoComplete="off"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="agency"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={2}
              controlId="formResourcesType"
              className="mb-3"
            >
              <Form.Label>{t("affiliates.formDigit")}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip">
                      Caso a agência não tenha dígito preencher com X ou 0.
                    </Tooltip>
                  }>
                  <span>
                    <FontAwesomeIcon
                      className="ml-2"
                      icon={["fal", "question-circle"]}
                    />
                  </span>
                </OverlayTrigger>
              </Form.Label>
              <Controller
                control={control}
                name="digitAgency"
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                  pattern: {
                    value: /[0-9xX]{1}/,
                    message: t("affiliates.formError"),
                  },
                }}
                render={({ field }: any) => (
                  <Form.Control
                    {...field}
                    //aria-invalid={errors?.digitAgency ? "true" : ""}
                    variant="standard"
                    margin="normal"
                    autoComplete="off"
                    maxLength={1}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="digitAgency"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={4}
              controlId="formResourcesType"
              className="mb-3"
            >
              <Form.Label>{t("affiliates.formAccount")}</Form.Label>
              <Controller
                control={control}
                name="account"
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                  pattern: {
                    value: /[0-9]{3}/,
                    message: t("affiliates.formError"),
                  },
                }}
                render={({ field }: any) => (
                  <Form.Control
                    {...field}
                    aria-invalid={errors?.account ? "true" : ""}
                    variant="standard"
                    margin="normal"
                    autoComplete="off"
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="account"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              sm={2}
              controlId="formResourcesType"
              className="mb-3"
            >
              <Form.Label>
                {t("affiliates.formDigit")}               
              </Form.Label>  
              <Controller
                control={control}
                name="digitAccount"
                rules={{
                  required: { value: true, message: t("affiliates.formError") },
                  pattern: {
                    value: /[0-9]{1}/,
                    message: t("affiliates.formError"),
                  },
                }}
                render={({ field }: any) => (
                  <Form.Control
                    {...field}
                    aria-invalid={errors?.digitAccount ? "true" : ""}
                    variant="standard"
                    margin="normal"
                    autoComplete="off"
                    maxLength={1}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="digitAccount"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
          </Row>

          <Row className="my-2">
            <Form.Group as={Col} controlId="BR" className="d-flex">
              <label htmlFor="chackPolice">
                <Form.Group>
                  <Controller
                    control={control}
                    name="checkPolice"
                    rules={{
                      required: {
                        value: true,
                        message: t("affiliates.termsWarning"),
                      },
                    }}
                    render={({ field }: any) => (
                      <Form.Check
                        {...field}
                        id="chackPolice"
                        name="check"
                        className="mr-2"
                        //defaultChecked={aceptPrivacy}
                        //disabled={profile}
                        required
                      />
                    )}
                  />
                </Form.Group>
              </label>
              <div>
                {t("checkoutSteps.acept")}
                <ModalPrivacy
                  type="privacy"
                  name={t("checkoutSteps.privacy")}
                  header={t("checkoutSteps.privacy")}
                  footer="yes"
                  close={t("privacyTerms.refuse")}
                  save={t("privacyTerms.accept")}
                  class="btn-privacy d-flex"
                  setValue={setValue}
                  setPrivacy={setPrivacy}
                >
                  <div className="modal-checkout">
                    <PrivacyTerms />
                  </div>
                </ModalPrivacy>
                {t("checkoutSteps.and")}
                <ModalPrivacy
                  type="privacy"
                  name={t("checkoutSteps.terms")}
                  header={t("checkoutSteps.terms")}
                  footer="yes"
                  close={t("privacyTerms.refuse")}
                  save={t("privacyTerms.accept")}
                  class="btn-privacy d-flex"
                  setValue={setValue}
                  setPrivacy={setPrivacy}
                >
                  <div className="modal-checkout">
                    <TermsUse />
                  </div>
                </ModalPrivacy>
              </div>
            </Form.Group>
            <ErrorMessage
              errors={errors}
              name="checkPolice"
              render={({ message }) => (
                <small style={{ color: "red", marginLeft: "25px" }}>
                  {message}
                </small>
              )}
            />
          </Row>

          {/* <div className="d-flex flex-column align-items-end pt-2">
            <div className="mb-3 bg-profile-recaptcha">
              {loading === false && lng === i18next.language ? (
                <>{renderCaptcha()}</>
              ) : (
                <></>
              )}
            </div>
          </div> */}

          <Row className="d-flex justify-content-end pt-2">
            <Col md={3}>
              <button
                className="btn btn-outline-primary mb-3 mb-md-0"
                onClick={() => back(2)}
              >
                {t("affiliates.formBack")}
              </button>
            </Col>
            <Col md={3}>
              <button type="submit" className="btn btn-primary">
                {t("affiliates.formFinalize")}
              </button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormBank;
