import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18next from "i18next";

import { Row } from "react-bootstrap";

export interface propNew {
    status: any,
    message: any,
    onValidated: any
}

const Newsletter: React.FC<propNew> = ({
    status, message, onValidated
}: propNew) => {
    const [t] = useTranslation();
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';
    const [email, setEmail] = useState('');
    const [sucess, setSucess] = useState(
        <div style={{ color: "green", textAlign: "center" }}>
            {
                lng === 'BR'
                    ?
                    "Obrigado! Inscrição realizada com sucesso!"
                    :
                    lng === "EN"
                        ?
                        "Thank you! Registration successful!"
                        :
                        lng === "ES"
                            ?
                            "Gracias. ¡Registro exitoso!"
                            :
                            "Obrigado! Inscrição realizada com sucesso!"
            }
        </div>
    );
    const [error, setError] = useState(
        <div style={{ color: "red", textAlign: "center" }}>
            {
                lng === 'BR'
                    ?
                    "E-mail já cadastrado!"
                    :
                    lng === "EN"
                        ?
                        "E-mail already registered"
                        :
                        lng === "ES"
                            ?
                            "Correo electrónico ya registrado"
                            :
                            "E-mail já cadastrado!"
            }
        </div>
    );
    const sending = useState(
        <div style={{ color: "blue", textAlign: "center" }}>
            {
                lng === 'BR'
                    ?
                    "Carregando..."
                    :
                    lng === "EN"
                        ?
                        "Loading..."
                        :
                        lng === "ES"
                            ?
                            "Cargando"
                            :
                            "Carregando..."
            }
        </div>
    );

    const handleSubmit = (e: any) => {
        e.preventDefault();
        email &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                //MERGE1: firstName,
            });
    }

    useEffect(() => {
        if (status === "success") {
            clearFields();

            setTimeout(() => {
                setSucess(<div></div>)
            }, 7500);
        }
        setSucess(
            <div style={{ color: "green", textAlign: "center" }}>
                {
                    lng === 'BR'
                        ?
                        "Obrigado! Inscrição realizada com sucesso!"
                        :
                        lng === "EN"
                            ?
                            "Thank you! Registration successful!"
                            :
                            lng === "ES"
                                ?
                                "Gracias. ¡Registro exitoso!"
                                :
                                "Obrigado! Inscrição realizada com sucesso!"
                }
            </div>
        )
    }, [lng, status])

    const clearFields = () => {
        //setFirstName('');
        setEmail('');
    }

    useEffect(() => {
        if (status === "error") {
            setTimeout(() => {
                setError(<div></div>)
            }, 7500);
        }
        setError(
            <div style={{ color: "red", textAlign: "center" }}>
                {
                    lng === 'BR'
                        ?
                        "E-mail já cadastrado!"
                        :
                        lng === "EN"
                            ?
                            "E-mail already registered"
                            :
                            lng === "ES"
                                ?
                                "Correo electrónico ya registrado"
                                :
                                "E-mail já cadastrado!"
                }
            </div>
        )
    }, [lng, status])

    return (
        <>
            <form onSubmit={(e) => handleSubmit(e)} action="https://c2rio.us13.list-manage.com/subscribe/post?u=1ab6480bde31e838a608aec2c&amp;id=d9d387ec11">
                <div className="input-group w-75">
                    <input
                        type="email"
                        name="E-mail"
                        id="email"
                        className="form-control py-2"
                        placeholder={t("footer.email")}
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-primary text-white input-group-text py-2" type="submit">
                            Ok
                        </button>
                    </div>
                </div>
                <Row className="my-2">
                    <div className='message-newsletter'>
                        {status === "sending" &&
                            sending
                        }
                        {status === "error" &&
                            error
                        }
                        {status === "success" &&
                            sucess
                        }
                    </div>
                </Row>
            </form>

        </>
    );
}

export default Newsletter;