import React, { useState, useEffect, Key } from "react";
import api from "../../../../services/api";
import './StoreHours.scss';

function StoreHours() {
    const [storeHours, setStoreHours] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function getStoreHours() {
            try {
                const { data } = await api.get(`/OpeningHours/GetOpeningHoursAsync`, config);
                if (data.status !== 400) {
                    if (data.data.log === 0 && data.data.data.filter((elem: any) => elem.status === 0).length > 0) {
                        setStoreHours(data.data.data);
                    }
                }
            } catch (error: any) {

            }
        }
        getStoreHours();
    }, []);

    return (
        <>
            {
                storeHours !== null &&
                <>
                    <div className="store-hours">
                        <ul>
                            <div className="store-hours-container">
                                {storeHours.filter((elem: any) => elem.status === 0).map((elem: any, index: Key) => {
                                    return (<>
                                        <li key={index}>{`${elem.portuguesDay} de ${new Date(elem.hourStart).toLocaleTimeString()} às ${new Date(elem.hourEnd).toLocaleTimeString()}`}</li>
                                    </>)
                                })}
                            </div>
                        </ul>
                    </div>
                </>
            }
        </>
    )
};

export default StoreHours;